import React from "react"
import { graphql } from "gatsby"
import { Box, Flex, Heading, Icon, Image, Link, Text } from "@chakra-ui/core"
import { Col, Container, Row } from "react-grid-system"
import { FaLinkedin, FaXingSquare, FaHome } from "react-icons/fa"

import { Carousel, Hero, SEO } from "../components"

const PartnerPage = ({ data: { doc } }) => {
  const { hero, partner, network, seo } = doc.nodes[0]

  if (!partner) return null
  return (
    <>
      <SEO seo={seo} />
      <Box fontSize={{ lg: "xl" }}>
        <Hero
          text={hero.title}
          primary
          small
          image={hero.image.childImageSharp.fluid}
          fluid
        />
        <Box my={{ base: "20", lg: "32" }}>
          <Container>
            <Heading
              fontSize={{ base: "4xl", xl: "5xl" }}
              pb={{ base: "10", lg: "20" }}
            >
              Unsere Partner
            </Heading>
            <Row>
              {partner.map((node, i) => {
                return (
                  <Col sm={12} md={6} lg={4} key={i}>
                    <Box mb={{ base: "10", lg: "20" }}>
                      <Image src={node.logo.publicURL} mb="10" />
                      <Link
                        href={node.link}
                        rel="noopener noreferrer"
                        target="_blank"
                        fontWeight="bold"
                        mb="4"
                        display="inline-block"
                        fontSize={{ base: "xl", lg: "3xl" }}
                      >
                        {node.name}

                        <Icon
                          name="chevron-right"
                          color="primary"
                          size="30px"
                        />
                      </Link>
                      <Text>{node.desc}</Text>
                    </Box>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </Box>
        <Container component={Box} textAlign="center">
          <Heading
            fontSize={{ base: "4xl", xl: "5xl" }}
            pb={{ base: "10", lg: "20" }}
          >
            Unser Netzwerk
          </Heading>
        </Container>
        <Carousel>
          {network.map((item, i) => {
            return (
              <Col key={i}>
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  h="100%"
                  px="4"
                >
                  {item.social && (
                    <Flex pb={{ base: 6, lg: 10 }}>
                      {item.social.homepage && (
                        <Link
                          href={item.social.homepage}
                          target="_blank"
                          rel="noopener noreferrer"
                          mr="4"
                        >
                          <Box
                            as={FaHome}
                            color="white"
                            size={{ base: "48px", lg: "65px" }}
                          />
                        </Link>
                      )}
                      {item.social.linkedin && (
                        <Link
                          href={item.social.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          mr="4"
                        >
                          <Box
                            as={FaLinkedin}
                            color="white"
                            size={{ base: "48px", lg: "65px" }}
                          />
                        </Link>
                      )}
                      {item.social.xing && (
                        <Link
                          href={item.social.xing}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Box
                            as={FaXingSquare}
                            color="white"
                            size={{ base: "48px", lg: "65px" }}
                          />
                        </Link>
                      )}
                    </Flex>
                  )}
                  <Text fontWeight="bold">{item.name}</Text>
                  <Text
                    px={{ lg: "10" }}
                    pt={{ base: "6", lg: "8" }}
                    textAlign="center"
                  >
                    {item.description}
                  </Text>
                </Flex>
              </Col>
            )
          })}
        </Carousel>
      </Box>
    </>
  )
}

export const query = graphql`
  {
    doc: allPartnerJson {
      nodes {
        hero {
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        seo {
          title
          description
        }
        partner {
          desc
          link
          logo {
            publicURL
          }
          name
        }
        network {
          description
          name
        }
      }
    }
  }
`

export default PartnerPage
